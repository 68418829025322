import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendErrorLog } from './sendErrorLog';

export const showToastNotification = (message, status, source = "") => {
  toast[status](message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

  if (status === "error")
    sendErrorLog({
      errorContent: message,
      pageId: source,
      remarks: "Toast Notification Shown",
    });
}