import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";
import { Link } from "react-router-dom";
import { Container, ContentSection, TitleAndDescription } from "../common-components/layout/PageLayoutParts";
import styled from "styled-components";
import { FAQs } from "../../utils/Constants";
import Accordion from "../common-components/Accordion";
import { getSlugFromString } from "../../utils/getSlugFromString";
import { useState, useEffect } from "react";
import { openAffiliateLinkIfValid } from "../../utils/openAffiliateLink";
import Comments from "../comments/Comments";

export function FAQ() {
    return (
        <>
            <Helmet>
                <title>{page_titles["faq"]}</title>
            </Helmet>

            <Container>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link className="breadcrumbs-link" to="/">
                                <i className="fa fa-home fa-xs"></i>
                            </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            FAQ
                        </li>
                    </ol>
                </nav>

                <ContentSection>
                    <TitleAndDescription
                        title={"Frequently Asked Questions (FAQs)"}
                        description={"As the proud creators of several innovative financial tools that are unique in India, we acknowledge that users may experience a learning curve in utilizing our tools effectively. Here, you will find a compilation of the most frequently asked questions, based on our interactions with the community."}
                    />
                    <FaqQuestions />
                    <Comments articleId={'FAQ'} />
                </ContentSection>
            </Container>
        </>
    );
}

const FaqQuestions = () => {
    const [openAccordionId, setOpenAccordionId] = useState(null);

    const handleQuestionClick = (id, faqSection, faq) => {
        setOpenAccordionId(openAccordionId === id ? null : id);
        updateUrl(id);
        const questionTitle = `${faq.question} - ${page_titles["faq"]}`;
        document.title = questionTitle;

        if (faqSection.section === "Real Cost of No Cost EMI") {
            const hash = `${getSlugFromString(faqSection.section)}-${getSlugFromString(faq.question)}`;
            const url = `/faq#${hash}`;
            openAffiliateLinkIfValid({}, url);
        }
    };

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        const hash = window.location.hash.substring(1);
        if (hash) {
            setOpenAccordionId(hash);

            FAQs.forEach((faqSection) => {
                faqSection.questions.forEach((faq) => {
                    const id = `${getSlugFromString(faqSection.section)}-${getSlugFromString(faq.question)}`;
                    if (id === hash) {
                        const questionTitle = `${faq.question} - ${page_titles["faq"]}`;
                        document.title = questionTitle; // Set the page title
                    }
                });
            });

            const scrollToElement = () => {
                const element = document.getElementById(hash);
                if (element) {
                    const headerOffset = 100;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "auto",
                    });
                }
                else {
                    setTimeout(scrollToElement, 50);
                }
            };

            scrollToElement();
        }
    }, []);

    return (
        <Root>
            {FAQs.map((faqSection, sectionIndex) => (
                <FaqSection key={sectionIndex}>
                    <SectionTitle>{faqSection.section}</SectionTitle>
                    {faqSection.questions.map((faq, quesIndex) => {
                        const id = `${getSlugFromString(faqSection.section)}-${getSlugFromString(faq.question)}`;
                        return (
                            <Accordion
                                id={id}
                                question={faq.question}
                                answer={faq.answer}
                                key={quesIndex}
                                isOpen={openAccordionId === id}
                                onQuestionClick={() => handleQuestionClick(id, faqSection, faq)}
                            />
                        );
                    })}
                </FaqSection>
            ))}
        </Root>
    );
}

const updateUrl = (id) => {
    const url = `${window.location.origin}${window.location.pathname}#${id}`;
    window.history.pushState({ path: url }, '', url);
}

const Root = styled.div``;

const FaqSection = styled.div`
    margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 35px;
`;

export default FAQ;
