import axios from "axios"
import { getAppConfig } from "../config/appConfig"
const appConfig = getAppConfig();

export const sendErrorLog = async ({
    errorContent,
    pageId,
    user,
    remarks,
}) => {

    const device = detectDeviceType();
    const os = detectOSType();
    const browser = detectBrowserType();
    const url = window.location.href

    try {
        axios.post(`${appConfig.errorLog}`,
            {
                errorContent,
                device,
                os,
                browser,
                pageId,
                user,
                remarks,
                sourceUrl: url
            }, {
            auth: {
                username: appConfig.username,
                password: appConfig.password
            }
        }).then((response) => {
            // console.log("Error log sent successfully", response)
        }).catch((error) => {
            console.error("Error sending error log", error)
        })

    }
    catch (error) {
        console.error('Not able to log the error : ', error);
    }
}

function detectOSType() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/windows/.test(userAgent)) {
        return 'windows';
    } else if (/macintosh|mac os x/.test(userAgent)) {
        return 'mac';
    } else if (/linux/.test(userAgent)) {
        return 'linux';
    } else if (/android/.test(userAgent)) {
        return 'android';
    } else if (/ios|iphone|ipad/.test(userAgent)) {
        return 'ios';
    } else {
        return 'other';
    }
}

function detectDeviceType() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/mobile|android|touch|silk|kindle|playbook|blackberry|opera mini/.test(userAgent)) {
        return 'mobile';
    } else if (/tablet|ipad|playbook/.test(userAgent)) {
        return 'tablet';
    } else {
        return 'laptop';
    }
}

function detectBrowserType() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/chrome|crios|crmo/.test(userAgent)) {
        return 'chrome';
    } else if (/firefox|fxios/.test(userAgent)) {
        return 'firefox';
    } else if (/safari/.test(userAgent) && !/chrome/.test(userAgent)) {
        return 'safari';
    } else if (/edg|edge/.test(userAgent)) {
        return 'edge';
    } else if (/opr\//.test(userAgent)) {
        return 'opera';
    } else {
        return 'other';
    }
}