import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import axios from "axios";
import { getAppConfig } from "../../config/appConfig";
import { showToastNotification } from "../../utils/ToastNotification";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";
import React from "react";
import { postNewUser } from "../../utils/postNewUser";

const appConfig = getAppConfig();

export default function MyProfile() {

    const { token, user, isUserLoggedIn } = useContext(AppContext);
    const [fetchedUser, setFetchedUser] = useState(getCachedUser());
    const navigate = useNavigate();
    const [updatedUser, setUpdatedUser] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [originalFieldValue, setOriginalFieldValue] = useState(null);
    const editingFieldRef = useRef(null);
    const [genders, setGenders] = useState(getCachedGenders());
    const [errors, setErrors] = useState({});

    const isLoggingOut = sessionStorage.getItem("userLoggedOut") === "true";

    useEffect(() => {
        if (isUserLoggedIn) {
            if (!fetchedUser) {
                try {
                    fetchUserFromServer(user.email, setFetchedUser)
                }
                catch (error) {
                    console.error('Error fetching user:', error);
                    showToastNotification("Oops! Something trouble fetching your details. Please log out and log back in to try again.", 'error', 'my-profile');
                }
            }
        }
        else {
            if (!isLoggingOut) {
                showToastNotification("You are not logged in. Please log in to try again.", 'error', 'my-profile');
                // console.log("User not logged in. Redirecting to login page.");
                navigate("/login");
            }
            sessionStorage.removeItem("userLoggedOut");
        }
    }, [isUserLoggedIn, user, token, navigate])

    useEffect(() => {
        if (fetchedUser) {
            fetchedUser.dob = formatDate(fetchedUser.dob);
            localStorage.setItem('fetchedUser', JSON.stringify(fetchedUser));
        }
        setUpdatedUser(fetchedUser)
        const getGenders = async () => {
            const fetchedGenders = await fetchGenders();
            setGenders(fetchedGenders);
        };
        getGenders();

    }, [fetchedUser])

    useEffect(() => {
        if (editingFieldRef.current && editingField !== null) {
            editingFieldRef.current.focus();
        }
    }, [editingField])

    const handleSave = () => {
        postUpdatedUserData(updatedUser, setFetchedUser)
        setEditingField(null);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    }

    const handleFieldUpdate = (field, value) => {
        setUpdatedUser((prevUser) => ({
            ...prevUser,
            [field]: value,
        }));
    };

    const handleEditField = (field) => {
        setOriginalFieldValue(updatedUser[field]);
        setEditingField(field);
    };

    const handleCancelEdit = () => {
        setUpdatedUser((prevUser) => ({
            ...prevUser,
            [editingField]: originalFieldValue,
        }));
        setEditingField(null);
        setOriginalFieldValue(null);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const renderProfileSection = (field, label, type = "text") => {
        const isEditMode = editingField === field;
        let fieldValue = updatedUser[field] == null ? "" : updatedUser[field];

        const handlePhoneValidation = (value) => {
            const phoneRegex = /^[0-9]{10}$/;
            if (field === "phone") {
                const isValid = phoneRegex.test(value);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [field]: {
                        isError: !isValid,
                        message: "Please enter 10 digit Indian mobile number",
                    },
                }));
            }
        };

        const handleNameValidation = (value) => {
            const nameRegex = /^[a-zA-Z\s]*$/;
            if (field === "name") {
                const isValid = nameRegex.test(value) && value.trim() !== "";
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [field]: {
                        isError: !isValid,
                        message: "Please enter name with alphabets and spaces only",
                    },
                }));
            }
        };

        const isError = isEditMode && errors[field]?.isError;

        return (
            <>
                <ProfileSection $editMode={isEditMode} key={`${label}-${field}`} $error={isError}>
                    <ProfileLabel>
                        <Label>{label}</Label>
                    </ProfileLabel>
                    <ProfileValueContainer>
                        {type !== "select" ? (
                            <ProfileValue
                                id={field}
                                ref={isEditMode ? editingFieldRef : null}
                                value={fieldValue == null ? "" : fieldValue}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    handleFieldUpdate(field, value);

                                    // Validate phone onChange
                                    if (field === "phone") handlePhoneValidation(value);
                                    if (field === "name") handleNameValidation(value);
                                }}
                                readOnly={!isEditMode}
                                onKeyDown={handleKeyDown}
                                type={type}
                            />
                        ) : (
                            <Dropdown
                                id={field}
                                ref={isEditMode ? editingFieldRef : null}
                                value={
                                    updatedUser[field] !== "UNSPECIFIED"
                                        ? updatedUser[field]
                                        : "Select"
                                }
                                onChange={(e) => {
                                    handleFieldUpdate(field, e.target.value);
                                    handleEditField(field);
                                }}
                            >
                                {genders.length > 1 &&
                                    genders.map((gender) => (
                                        <option key={gender} value={gender}>
                                            {formatGender(gender)}
                                        </option>
                                    ))}
                            </Dropdown>
                        )}
                        <ActionIcon>
                            {isEditMode ? (
                                <ActionIconGroup>
                                    <i
                                        className="fa fa-save"
                                        style={{ pointerEvents: isError ? "none" : "auto", opacity: isError ? 0.5 : 1 }}
                                        onClick={() => {
                                            if (!isError) handleSave();
                                        }}
                                    />
                                    <i className="fa fa-close" onClick={handleCancelEdit} />
                                </ActionIconGroup>
                            ) : !updatedUser[field] ? (
                                <i className="fa fa-add" onClick={() => handleEditField(field)} />
                            ) : type !== "select" ? (
                                <i className="fa fa-pen" onClick={() => handleEditField(field)} />
                            ) : null}
                        </ActionIcon>
                    </ProfileValueContainer>
                </ProfileSection>
                <ErrorMessage>{isError && errors[field]?.message}</ErrorMessage>
            </>
        );
    };

    return (
        <div className="container py-3">

            <Helmet>
                <title>{page_titles["my-profile"]}</title>
            </Helmet>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link className="breadcrumbs-link" to="/"><i className="fa fa-home fa-xs"></i></Link></li>
                    <li className="breadcrumb-item active" aria-current="page">My Profile</li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    <ProfileContainer>
                        {fetchedUser && updatedUser && (
                            <>
                                {/* Name */}
                                {renderProfileSection("name", "Name")}
                                {/* Email */}
                                <ProfileSection>
                                    <ProfileLabel>
                                        <Label>Email</Label>
                                    </ProfileLabel>
                                    <ProfileValueContainer>
                                        <ProfileValue
                                            value={updatedUser.email}
                                            onChange={(e) => {
                                                setUpdatedUser(old => ({
                                                    ...old,
                                                    email: e.target.value
                                                }))
                                                setEditingField("email");
                                            }}
                                            disabled
                                        />
                                    </ProfileValueContainer>
                                </ProfileSection>
                                {renderProfileSection("phone", "Phone", "tel")}
                                {renderProfileSection("dob", "Date of Birth", "date")}
                                {renderProfileSection("gender", "Gender", "select")}
                            </>
                        )}
                    </ProfileContainer>
                </div>
                <div className="col-md-1"></div>
            </div>
        </div>
    )
}

const getCachedUser = () => {
    const cachedUser = localStorage.getItem('fetchedUser');
    return cachedUser ? JSON.parse(cachedUser) : null;
}

const fetchUserFromServer = async (email, setFetchedUser, postedNewUser = false) => {
    // console.log("postedNewUser", postedNewUser);
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${appConfig.userByEmail}`, {
            params: {
                email: email
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (!response || !response.data || response.data.length === 0) {
            if (!postedNewUser) {
                postNewUser({ email: email, name: null }).then(() => fetchUserFromServer(email, setFetchedUser, true));
            }
            else {
                throw new Error("User not found");
            }
        }
        setFetchedUser(response.data[0])
    } catch (error) {
        console.error('Error finding user:', error);
        throw error;
    }
}

const postUpdatedUserData = async (updatedUser, setFetchedUser) => {
    try {
        const token = localStorage.getItem('token');
        await axios.post(`${appConfig.user}/update`, {
            name: updatedUser.name,
            email: updatedUser.email,
            phone: updatedUser.phone,
            photoUrl: updatedUser.photoUrl,
            gender: updatedUser.gender,
            dob: updatedUser.dob,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        // If successful, fetch updated user data from the server
        await fetchUserFromServer(updatedUser.email, setFetchedUser);

    } catch (error) {
        console.error('Error posting updated user details:', error);

        // Roll back to the cached user data from local storage
        const cachedUser = getCachedUser();
        if (cachedUser) {
            setFetchedUser(cachedUser);
            showToastNotification("Sorry, we couldn't catch your updates in profile, please try again.", 'error', 'my-profile');
        } else {
            showToastNotification("Sorry, we couldn't catch your updates in profile, please try again.", 'error', 'my-profile');
        }
    }
};

const fetchGenders = async () => {
    let genders = getCachedGenders();
    if (genders.length > 0) {
        return genders;
    }
    try {
        const token = localStorage.getItem('token');
        genders = await axios.get(`${appConfig.genders}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => response.data);
        genders.sort((a, b) => {
            if (a === "UNSPECIFIED") return -1;
            if (b === "UNSPECIFIED") return 1;
            return 0;
        });

        localStorage.setItem('genderOptions', JSON.stringify(genders));
        return genders;
    } catch (error) {
        console.error('Error fetching genders:', error);
        return [];
    }
}

const getCachedGenders = () => {
    const cachedGenders = localStorage.getItem('genderOptions');
    return cachedGenders ? JSON.parse(cachedGenders) : [];
}

const formatGender = (gender) => {
    if (gender === "UNSPECIFIED") {
        return "Select"
    }
    return gender
        .toLowerCase()                // Convert the entire string to lower case
        .split('_')                   // Split the string by underscores
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' ');                   // Join the words with spaces
};

// Styled components
const Label = styled.span`
    margin-left: 20px;
`;

const ActionIcon = styled.div`
    margin-left: 10px;
    margin-right: 20px;
    i{
        width: 20px;
    }
`;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 90vh;
`;

const ProfileSection = styled.div`
    width: 100%;
    padding: 30px 0px;
    display: flex;
    border-bottom: ${({ $editMode, $error }) => `1px ${$editMode ? ($error ? 'red' : 'white') : 'grey'} solid`};

    @media (max-width: 576px) {
        text-align: center;
        flex-direction: column;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 0.8rem;
    text-align: right;
    display: block;
    width: 100%;
    position: relative;
    top: -20px
`

const ProfileLabel = styled.p`
    width: 50%;  
    @media (max-width: 576px) {
        width: 100%;
    }
`;

const ProfileValueContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    i{
        cursor: pointer;
    }

    input{
        cursor: default;
    }

    *:focus{
        cursor: auto;
    }

    @media (max-width: 576px) {
        margin: 10px auto;
        width: 80%;
        justify-content: center;
        input{
            padding: 10px;
            border-bottom: 1px solid var(--light-blue);
            text-align: center;
        }

        select{
            margin-left: 25px;
        }

        :disabled{
            border-bottom: grey;
        }

        div{
            margin: 0;
        }
    }
    
`;

const ProfileValue = styled.input`
    margin: 0;
    background-color: transparent;
    outline: none;
    color: white;
    width: 100%;
    flex: 1;

    &:disabled{
        color: grey;
    }
`;

const ActionIconGroup = styled.div`
    display: flex;
    gap: 30px;
`
const Dropdown = styled.select`
    margin: 0;
    background-color: white;
    outline: none;
    color: black;
    width: 50%;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    border-right: 10px solid transparent;

    option{
        color: black;
        padding: 15px;
    }
`