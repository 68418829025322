import { Link } from "react-router-dom";
import Logo from "../../assets/Full_Logo.svg";
import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";
import { Banner } from "../banners/BannerWithButtons";
import { openAffiliateLinkIfValid } from "../../utils/openAffiliateLink";
import { RefferalModal } from "./modals/ReferralModal";
import { CreditCardModal } from "./modals/CreditCardModal";

function HomePage() {

    return (
        <div className="bg-black ">
            <Helmet>
                <title>{page_titles["home"]}</title>
            </Helmet>

            <div className="home child-center ">
                <div className="container mx-auto ">
                    <div className="row">
                        <div className="col"></div>

                        <div className="col-10">
                            <div className="row">
                                <div className="col-12 col-md child-center text-white">
                                    <div className="child-center">
                                        <Link className="navbar-brand home-logo p-5" to="/">
                                            <img width={80} src={Logo} alt="pFinTools Logo" />
                                        </Link>
                                        <h2 className="text-center text-light-blue"><strong>Powerful, Practical</strong><br /> <i>&</i> a little <strong>Pedantic</strong> <br /> Financial Tools</h2>
                                    </div>
                                </div>
                                <div className="col-12 col-md">
                                    <div className="row child-center text-white">
                                        <div className="home-card">
                                            <p>
                                                <span>
                                                    <Link to="/upcoming-dividend-calendar" className="nav-link text-pastel-green text-pastel-green-hover">Dividend <br /> Calendar</Link>
                                                </span>
                                            </p>
                                            <p>
                                                <span>
                                                    <Link to="/no-cost-emi-calculator" onClick={() => openAffiliateLinkIfValid({}, "/no-cost-emi-calculator")} className="nav-link text-pastel-green text-pastel-green-hover">Real Cost of<br /> No Cost EMI</Link>
                                                </span>
                                            </p>
                                            <p>
                                                <span>
                                                    <Link type="button" data-bs-toggle="modal" data-bs-target="#referralModal" className="nav-link">Start <br /> Investing</Link>
                                                </span>
                                            </p>
                                            <p>
                                                <span>
                                                    <Link type="button" data-bs-toggle="modal" data-bs-target="#ccModal" className="nav-link" target={"_blank"} rel="noreferrer">Get a new <br /> Credit Card</Link>
                                                </span>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col"></div>

                    </div>
                </div>
            </div>

            {/* Amazon Banner */}
            <Banner />

            {/* Referral links Modal  */}
            <RefferalModal />
            {/* Credit Card Modal */}
            <CreditCardModal />

        </div>
    )
}

export default HomePage;