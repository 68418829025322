import React, { useState, useEffect, useContext } from 'react';
import { getAppConfig } from '../../config/appConfig';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Spinner from '../Spinner';
import Comments from '../comments/Comments';
import { showToastNotification } from '../../utils/ToastNotification';

const appConfig = getAppConfig();

export default function BlogPost() {
    const [blogPost, setBlogPost] = useState({
        title: ""
    });
    const [loading, setLoading] = useState(true);

    // TODO: if no blog fetched with pageId, show 404.
    const { slug } = useParams();

    let blogContent = { __html: "" };

    if (blogPost.content)
        blogContent = { __html: blogPost.content };

    useEffect(() => {
        fetchBlogPost(slug, setBlogPost, setLoading);
    }, []);

    if (loading) {
        //TODO: Add a loader here
        return <div className="loader-wrapper"><Spinner /></div>
    }

    return (
        <>
            <Helmet>
                <title>{blogPost.title + " | pFinTools Blogs"}</title>
            </Helmet>

            <div className="container py-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link className="breadcrumbs-link" to="/">
                                <i className="fa fa-home fa-xs"></i>
                            </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                            <Link className="breadcrumbs-link" to="/blogs">
                                Blogs
                            </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {blogPost.title}
                        </li>
                    </ol>
                </nav>

                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-12 col-md-10">
                        {blogPost && (
                            <section className="blogpost-content max-w-3xl mx-auto">
                                <h1 className="py-3">{blogPost.title}</h1>
                                {blogPost.author ? (
                                    <>
                                        <p>
                                            By <span className="text-dark-green">{blogPost.author}</span>
                                            <span className="design font-size-small">{blogPost.designation}</span>
                                        </p>
                                    </>
                                ) : (
                                    <></>
                                )}

                                <div className='blog-body py-4' dangerouslySetInnerHTML={blogContent}></div>
                            </section>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-12 col-md-10">
                        <Comments />
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div >
        </>
    );
}

function fetchBlogPost(slug, setBlogPost, setLoading) {
    try {
        const token = localStorage.getItem('token');
        axios.get(appConfig.blog + `/${slug}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => setBlogPost(response.data))
            .then(() => setLoading(false))
            .catch(error => console.log(error));
    } catch (error) {
        console.log(error);
        showToastNotification("Uh oh! Something went wrong.", "error", 'fetch-blog-post');
    }
}